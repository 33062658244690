import React from 'react';

import Typography from "../../components/Typography";

export default function CottageRules() {

    return (
        <React.Fragment>
            <div>
                <Typography variant={'h5'}>
                    Arrival and departure:
                </Typography>
                <Typography variant={'label'}>
                    Arrival from 14h30 on the day of arrival and should be vacated by 10h00 on the day of departure.
                    Earlier arrival or later departure times can be accommodated with advanced notice.
                </Typography>
                <Typography variant={'h5'}>
                    Pets:
                </Typography>
                <Typography variant={'label'}>
                    Unfortunately no pets allowed at the cottages.
                </Typography>
                <Typography variant={'h5'}>
                    Parking:
                </Typography>
                <Typography variant={'label'}>
                    We only have 2 parking bays at our property for two vehicles. Please park your vehicles in the designated space, ensuring cars do not encroached onto the road surface.
                    There is also the possibility for additional cars to park along our quiet, residential street, provided they do not block any neighbours’ property access.
                </Typography>
                <Typography variant={'h5'}>
                    Garbage:
                </Typography>
                <Typography variant={'label'}>
                    We like to do our bit for the environment and we ask that our guests do the same!
                    Please dispose of all garbage in the waste bin container in the kitchen, when the waste bins are full please put the full bags in the Municipal refuge bins
                    outside. Please do not place any refuse within or outside the premises except in the containers and/or the refuse areas provided.
                </Typography>

            </div>
        </React.Fragment>
    )

}
