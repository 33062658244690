import './App.css';

// Routes
import {Routes} from './routes/Routes';
import {BrowserRouter} from 'react-router-dom';

// Material UI
import {
  createTheme,
  MuiThemeProvider
} from '@material-ui/core/styles';

// Theme
const theme = createTheme({
  palette: {},
  typography: {
    fontFamily: ['Roboto-Light', 'Roboto-Bold', 'Roboto-Regular']
  }
});

function App() {
  return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <Routes/>
        </MuiThemeProvider>
      </BrowserRouter>
  );
}

export default App;
