import React from 'react';
import Header from "../layout/Header";

import './Test.scss';

export default function Test(props){

    return(
        <React.Fragment>
            <Header
                data-testid={"main-header"}
                //profileSidePanelOpen={profileSidePanelOpen}
                //handleSidePanelOpen={() => handleSidePanelOpen()}
            />
            
            <div className={'test'}>
                Content
                <div>cell1</div>
                <div>cell2</div>
            </div>
        </React.Fragment>
    )
}
