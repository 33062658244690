import React, {useState, useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    useTheme
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import ReactPlayer from "react-player";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.css";

import {isEmpty} from "lodash";

const useStyles = makeStyles(theme => ({
    slider: {
        paddingBottom: "1.8em"
    },
    playButton: {
        position: "absolute",
        fontSize: "3em",
        color: 'black'//colors.base["black"]
    }
}));

export default function ImageSlider(props) {

    const theme = useTheme();
    const mediaSM = useMediaQuery(theme.breakpoints.up("sm"));
    const classes = useStyles();

    let thumbnailSize = {
        height: props.height ? (props.height * 0.2 + "em") : "5em",
        width: props.height ? (props.height * 0.2 + "em") : "5em"
    };

    const [images, setImages] = useState([]);
    const [loading] = useState(false);
    //const [playEnabled, setPlayEnabled] = useState(true);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const sliderRef = useRef(this);

    setTimeout(() => {
    }, 600);

    const settings = {
        dots: props.dots !== undefined ? props.dots : false,
        thumbnail: props.thumbnail !== undefined ? props.thumbnail : true,
        infinite: props.infinite || true,
        speed: props.speed || 500,
        slidesToShow: props.slidesToShow || 1,
        slidesToScroll: props.slidesToScroll || 1,
        arrows: props.arrows || false,
        swipeToSlide: props.swipeToSlide || true,
        // afterChange: current => (setPlayEnabled(false), setTimeout(() => {
        //     setActiveSlideIndex(current);
        // }, 300)),
        focusOnSelect: true,
        //centerMode: true,
        adaptiveHeight: true,
        autoPlay: true,
        centerPadding: '1px'
    };

    useEffect(() => {
        if (props.images && props.images.length > 0) {
            setImages(props.images);
        }
    }, [props.images]);

    const goToSilde = (e, index, test) => {
        //setPlayEnabled(false);
        setActiveSlideIndex(index);
        sliderRef.current.slickGoTo(index);
    };

    return (
        <div
            className={"noselect"}
            style={{
                display: "block !important",
                position: "relative"
            }}
        >
            {images && !isEmpty(images)
                    ?
                    <Slider ref={sliderRef} {...settings} className={classes.slider + " noselect"}
                            style={{borderBottom: props.disableBorders ? "none" : "1px solid #ebebeb"}}>
                        {
                            images.map((item, index) => (
                                <div key={index}
                                >
                                    {
                                        item.type === "videos"
                                            ?
                                            <div style={{
                                                height: props.height ? (props.height + "em") : props.height === "auto" ? "auto" : "30em",
                                                maxHeight: '20em',
                                                maxWidth: '30em',
                                                //maxWidth: props.width ? (props.width + "em") : props.width === "100%" ? "100%" : "20em",
                                                margin: "auto",
                                            }}>
                                                <ReactPlayer
                                                    url={item.url}
                                                    controls width={"100%"}
                                                    height={"100%"}
                                                />
                                            </div>
                                            :
                                            <div style={{
                                                height: props.height ? (props.height + "em") : props.height === "auto" ? "auto" : "30em",
                                                //minHeight: '20em',
                                                maxHeight: '20em',
                                                maxWidth: '30em',//props.width ? (props.width + "em") : props.width === "100%" ? "100%" : "20em",
                                                margin: "auto",
                                                backgroundImage: "url(" + item.url + ")",
                                                backgroundSize: "contain",
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat"
                                            }}/>
                                    }
                                </div>
                            ))
                        }
                    </Slider>
                    :
                    <div style={{
                        width: props.width ? (props.width + "em") : "20em",
                        margin: "auto"
                    }}>
                        {loading ? (<div>loading...</div>
                            // <Loader styles={{paddingTop: props.height ? (props.height / 2.6 + "em") : "8em"}}/>
                        ) : (
                            // <NoData/>
                            <div>NoData</div>)}

                    </div>
            }

            {
                images && !isEmpty(images) && settings.thumbnail === true && mediaSM
                    ?
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        paddingTop: "1em",
                        paddingBottom: "1em",
                        flexWrap: "wrap",
                        borderBottom: props.disableBorders ? "none" : "1px solid #ebebeb"
                    }}>
                        {
                            images.map((item, index) => (
                                <div key={index}
                                     style={
                                         activeSlideIndex === index ?
                                             {
                                                 borderBottom: "0.2em solid #b4b4b4",
                                                 margin: "1em",
                                                 cursor: "pointer"
                                             }
                                             :
                                             {
                                                 margin: "1em",
                                                 cursor: "pointer"
                                             }
                                     }
                                     onClick={(e) => goToSilde(e, index, this)}
                                >
                                    {
                                        item.type === "videos"
                                            ?
                                            <div style={{
                                                height: thumbnailSize.height,
                                                width: thumbnailSize.width,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundImage: "linear-gradient(45deg, #00000024, transparent)"
                                            }}>
                                                <PlayArrowIcon className={classes.playButton}/>
                                                <ReactPlayer
                                                    url={item.url}
                                                    controls={false}
                                                    width={"100%"}
                                                    height={"100%"}
                                                />
                                            </div>
                                            :
                                            <div style={{
                                                height: thumbnailSize.height,
                                                width: thumbnailSize.height,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}>
                                                <img style={{
                                                    maxHeight: "100%",
                                                    maxWidth: "100%"
                                                }} src={item.url} alt="No image available"/>
                                            </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                    :
                    null
            }
        </div>
    );
}
