import React, {useEffect, useState} from 'react';

//Material-UI
import {
    makeStyles,
} from '@material-ui/core';

//Utility
import {isEmpty} from 'lodash';

const useStyles = makeStyles(() => ({
    h1: {
        fontSize: '4rem',
        letterSpacing: 1,
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    h2: {
        fontSize: '3rem',
        letterSpacing: 1,
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    h3: {
        fontSize: '2rem',
        letterSpacing: 1,
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    h4: {
        fontSize: '1.5rem',
        letterSpacing: 1,
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    h5: {
        fontSize: '1.25rem',
        letterSpacing: 1,
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    body: {
        fontSize: '1rem',
        letterSpacing: 1,
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    label: {
        fontSize: '0.9rem',
        letterSpacing: 0.5,
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    button: {
        fontSize: '0.8rem',
        letterSpacing: 1,
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    nav: {
        fontSize: '0.6rem',
        letterSpacing: 1,
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    info: {
        fontSize: '0.75rem',
        wordBreak: 'break-word',
        hyphens: 'auto'
    },
    wrap: {
        display: '-webkit-box !important',
        '-webkit-line-clamp': props => props.lines ? props.lines : 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
    },
}));

export default function Typography(props) {

    //Classes
    const classes = useStyles();

    //States
    const [params, setParams] = useState({});

    useEffect(()=>{
        let tempParams = {
            ...params,
            variant: props.variant && !isEmpty(props.variant) ? props.variant : 'body',
            contrast: props.contrast && !isEmpty(props.contrast) ? props.contrast : 'lightMode',
            color: props.color && !isEmpty(props.color) ? props.color : 'black',
            fontWeight: props.fontWeight && !isEmpty(props.fontWeight) ? props.fontWeight : 'default',
            textDecoration: props.textDecoration && !isEmpty(props.textDecoration) ? props.textDecoration : 'none',
            display: props.display && !isEmpty(props.display) ? props.display : 'block',
            align: props.align && !isEmpty(props.align) ? props.align : 'left',
            nowrap: props.nowrap && !isEmpty(props.nowrap) ? props.nowrap : false,
            characterCap: props.characterCap && !isEmpty(props.characterCap) ? props.characterCap : -1,
            textShadow: props.textShadow ? props.textShadow : '',
            opacity: props.opacity ? props.opacity : '1',
            cursor: props.cursor ? props.cursor : 'default'
        };
        setParams(tempParams);
    },[props])

    const determineFamily = (family) => {
        switch (family) {
            case 'dinpro':
                return params.fontWeight === 'bold' ? 'DINPro' :'DINPro-Light';
            case 'roboto':
            default:
                return params.fontWeight === 'bold' ? 'Roboto-Bold' : params.fontWeight === 'light' ? 'Roboto-Light' : 'Roboto-Regular';
        }
    }

    return(
        <React.Fragment>
            <div
                style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}
                data-testid={props.testId || ''}
            >
                <div className={classes[params.variant]} style={{
                    display: params.display,
                    color: params.color,
                    fontFamily: determineFamily(params.family),
                    textDecoration: params.textDecoration,
                    textAlign: params.align,
                    margin: params.align && params.align === 'center' && '0 auto',
                    textShadow: params.textShadow,
                    opacity: params.opacity,
                    cursor: params.cursor
                }}>
                    {props.children && !isEmpty(props.children) ? props.children : ''}
                </div>
            </div>
        </React.Fragment>
    )
}
