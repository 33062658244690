import React from 'react';

// Routes
import {Route, Switch} from 'react-router-dom';
import RoutePaths from './RoutePaths';

//Modules
import Landing from "../modules/landing/Landing";
//import Map from "../modules/map/Map";
//import Register from "../modules/register/Register";
//import Confirmation from "../modules/payment/Confirmation";
//import Cancellation from "../modules/payment/Cancellation";
import Test from "../modules/test/Test";

export const Routes = () => {
    return (
        <Switch>
            {/*AUTHENTICATE*/}
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={RoutePaths.AUTHENTICATE}*/}
            {/*  render={route => (*/}
            {/*    <Landing route={route} authenticate={true}/>*/}
            {/*  )}*/}
            {/*/>*/}

            {/*DEFAULT*/}
            <Route
                exact
                path={RoutePaths.DEFAULT}
                render={route => (
                    <Landing route={route}/>
                )}
            />

            {/*LANDING*/}
            <Route
                exact
                path={RoutePaths.HOME}
                render={route => (
                    <Landing route={route}/>
                )}
            />

            {/*/!*MAP*!/*/}
            {/*<Route*/}
            {/*    exact*/}
            {/*    path={RoutePaths.MAP}*/}
            {/*    render={route => (*/}
            {/*        */}
            {/*    )}*/}
            {/*/>*/}

            {/*PAYMENT CONFIRMATION*/}
            {/*<Route*/}
            {/*    exact*/}
            {/*    path={RoutePaths.PAYMENT_CONFIRMATION}*/}
            {/*    render={route => (*/}
            {/*        <Confirmation route={route}/>*/}
            {/*    )}*/}
            {/*/>*/}

            {/*PAYMENT CANCELLATION*/}
            {/*<Route*/}
            {/*    exact*/}
            {/*    path={RoutePaths.PAYMENT_CANCELLATION}*/}
            {/*    render={route => (*/}
            {/*        <Cancellation route={route}/>*/}
            {/*    )}*/}
            {/*/>*/}

            {/*REGISTER*/}
            {/*<Route*/}
            {/*    exact*/}
            {/*    path={RoutePaths.REGISTER}*/}
            {/*    render={route => (*/}
            {/*        <Register route={route}/>*/}
            {/*    )}*/}
            {/*/>*/}

            <Route
                exact
                path={RoutePaths.TEST}
                render={route => (
                    <Test route={route}/>
                )}
            />
        </Switch>
    )
}