const RoutePaths = {
    // AUTH
    AUTHENTICATE: '/authenticate',

    // DEFAULT
    DEFAULT: '/',

    // HOME
    HOME: '/home',

    // LANDING
    LANDING: '/landing',

    // PAYMENT CONFIRMATION
    PAYMENT_CONFIRMATION: '/confirmation',

    // PAYMENT CONFIRMATION
    PAYMENT_CANCELLATION: '/cancellation',

    // MANAGE SUBSCRIPTION
    SUBSCRIPTION_MANAGE: '/subscription',

    // LOGOUT
    LOGOUT: '/logout',

    // MAP
    MAP: '/map',

    // REGISTER
    REGISTER: '/register',

    //TEST
    TEST: '/test'
}

export default RoutePaths;