import React from 'react';

//Material-UI
import {
  Grid,
  makeStyles,
} from '@material-ui/core';

//Components
import Typography from "../../components/Typography";

const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100px',
    backgroundColor: 'black',
  },
  headerMain: {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'flex-start'
    alignItems: 'flex-end'
  },
  headerMenu: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    //height: '75px',
    backgroundColor: 'white',
    //width: '100%'
  },
  headerTitle: {
    margin: 5
  },
  icon: {
    color: 'white'
  },
  separator: {
    width: '14em',
    borderBottom: 'solid 1px',
    margin: '10px 0 10px 0'
  },
  footerText: {
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
  }
}));

export default function Footer(props){
  
  //Classes
  const classes = useStyles();
  
  return(
    <React.Fragment>
      <Grid container justifyContent={"space-between"} className={classes.footer}>
        <Grid item xs={12}>
          <div className={classes.footerText}>
            <div>
              <Typography variant={'button'} color={'white'}>
                @Copyright 2021 Pro Patria
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
