import React from 'react';
import logo from '../../assets/images/gif/logo.gif';

//Material-UI
import {
    Grid,
    makeStyles,
} from '@material-ui/core';

//Components
import Typography from "../../components/Typography";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        height: '75px',
        backgroundColor: 'white',
        margin: '2em'
    },
    headerMain: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },
    headerMenu: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'right',
        backgroundColor: 'white',
        marginRight: '5em',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },
    spacer: {
        borderRight: '1px solid gray',
        margin: '0 10px 0 10px',
        [theme.breakpoints.down('sm')]: {
            margin: '0 6px 0 6px',
        }
    },
    headerTitle: {
        margin: 5
    },
    icon: {
        color: 'white'
    },
    separator: {
        width: '14em',
        borderBottom: 'solid 1px',
        margin: '10px 0 10px 0'
    },
    link: {
        color: "inherit",
        textDecoration: "inherit",
        cursor: 'pointer'
    },
    logo: {
        width: '80px',
        height: '80px',
        marginRight: '10px'
    }
}));

export default function Header(props){

    //Classes
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const classes = useStyles();

    return(
        <React.Fragment>
            <Grid container justifyContent={"space-between"} className={classes.header}>
                <Grid item xs={12} sm={4}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <img src={logo} className={classes.logo} alt={'MOTH'}/>
                        <div className={'headerMain'}>
                            <div>
                                <Typography variant={'h3'} fontFamily={'DINPro-Light'} className={classes.headerTitle} >
                                    Moth Cottages
                                </Typography>
                            </div>
                            <div className={classes.separator}></div>
                            <div>
                                <Typography variant={'label'}>
                                    L U X U R Y&nbsp;&nbsp;&nbsp;G E T A W A Y S
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <div className={classes.headerMenu}>
                        <div>
                            {matches ? (<Typography variant={'label'}>
                                <a href={'#accommodation'} color={'inherit'} className={classes.link} rel={'noreferrer'}>
                                    ACCOMMODATION
                                </a>
                            </Typography>) : ( <Typography variant={'button'}>
                                <a href={'#accommodation'} color={'inherit'} className={classes.link} rel={'noreferrer'}>
                                    ACCOMMODATION
                                </a>
                            </Typography>)}
                        </div>
                        <div className={classes.spacer}></div>
                        <div>
                            {matches ? (<Typography variant={'label'}>
                                <a href={'#gallery'} color={'inherit'} className={classes.link} rel={'noreferrer'}>
                                    GALLERY
                                </a>
                            </Typography>) : ( <Typography variant={'button'}>
                                <a href={'#gallery'} color={'inherit'} className={classes.link} rel={'noreferrer'}>
                                    GALLERY
                                </a>
                            </Typography>)}
                        </div>
                        <div className={classes.spacer}></div>
                        <div>
                            {matches ? (<Typography variant={'label'}>
                                <a href={'#bookings'} color={'inherit'} className={classes.link} rel={'noreferrer'}>
                                    BOOKINGS
                                </a>
                            </Typography>) : ( <Typography variant={'button'}>
                                <a href={'#bookings'} color={'inherit'} className={classes.link} rel={'noreferrer'}>
                                    BOOKINGS
                                </a>
                            </Typography>)}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
