import React, {useState} from 'react';
import Header from "../layout/Header";
import {makeStyles} from "@material-ui/core";
import Typography from "../../components/Typography";
import Background from '../../assets/images/bakoven/MainImage.jpg';
import Footer from "../layout/Footer";
import CottageLocation from "../cottages/CottageLocation";
import CottageOverview from "../cottages/CottageOverview";

import ImageSlider from "../../components/sliders/ImageSlider";
import HeaderNav from "../layout/HeaderNav";
import CottageBooking from "../cottages/CottageBooking";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import image1 from "../../assets/images/bakoven/JJT_5278_edit.jpg";
import image2 from "../../assets/images/bakoven/JJT_5290_edit.jpg";
import image3 from "../../assets/images/bakoven/JJT_5300_edit.jpg";
import image4 from "../../assets/images/bakoven/JJT_5301_edit.jpg";
import image5 from "../../assets/images/bakoven/JJT_5305_edit.jpg";
import image6 from "../../assets/images/bakoven/JJT_5311_edit.jpg";
import image7 from "../../assets/images/bakoven/JJT_5534_edit.jpg";
import image8 from "../../assets/images/bakoven/JJT_5554_edit.jpg";
import image9 from "../../assets/images/bakoven/JJT_5570_edit.jpg";
import image10 from "../../assets/images/bakoven/JJT_5599_edit.jpg";
import bookingForm from "../../assets/files/Bakoven-Cottage-Booking-Form-2022v1.8.pdf";
import CottageRules from '../cottages/CottageRules';
import CottageRules2 from '../cottages/CottageRules2';

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    section1: {
        display: 'flex',
        height: '50em',
        width: '100vw',
        justifyContent: "center",
        backgroundImage: 'url(' + Background + ')',
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        backgroundPosition: "top left",
        backgroundBlendMode: "darken",
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.5em',
        }
    },
    evenColumns: {
        display: 'flex',
        flexWrap: 'wrap',
        '& *': {
            flexBasis: '30%',
            flexGrow: '1',
            flexShrink: '1',
            margin: '0.2em',
            [theme.breakpoints.down('sm')]: {
                flexBasis: '50%'
            }
        },
        gap: '1em',
    },
    slider: {
        width: '100%',
        height: '100%',
    },
    playerWrapper: {
        width: 'auto', // Reset width
        height: 'auto', // Reset height
        position: 'relative' // Set to relative
    },
    reactPlayer: {
        paddingTop: '56.25%', // Percentage ratio for 16:9
        position: 'relative' // Set to relative
    },
    gallery: {
        backgroundColor: '#B6985C'
    }
}));

const cottageData = {
    address: {
        street: '20 & 21 Beta Road',
        suburb: 'Bakoven',
        city: 'Cape Town',
        tel: '+27(0)82 572 5561',
        email: 'mothcottage@gmail.com',
        email2: 'mothbilly.hill@gmail.com'
    },
    calendar: [{
        name: 'ElAlamein',
        url: 'https://calendar.google.com/calendar/embed?src=f6v39tplj7hfp3m0kia71jkkd0%40group.calendar.google.com&ctz=Africa%2FJohannesburg&amp;color=%23795548&amp;showTz=0&amp;showTz=0&amp;showCalendars=0&amp;showTitle=1&amp;showTabs=0&amp;showPrint=0',
    }, {
        name: 'DelvilleWood',
        url: 'https://calendar.google.com/calendar/embed?src=92m93d72huql1vfsidig3g7pgc%40group.calendar.google.com&ctz=Africa%2FJohannesburg&amp;color=%23795548&amp;showTz=0&amp;showCalendars=0&amp;showTitle=1&amp;showTabs=0&amp;showPrint=0'
    }],
    attractions: [
        'Beaches – sun, sea & ski',
        'Yachting and boat trips',
        'Mountains – hiking',
        'Cycling',
        'Fishing',
        'Restaurants, shops, and curios',
        'Table Mountain – cable car'
    ],
    gallery: {
        videoUrl: 'https://youtu.be/yI3kMr-cVfg',
        images: [
            {type: 'videos', url: 'https://www.youtube.com/watch?v=Fl-l1yJHqhE'},
            {type: 'image', url: image1},
            {type: 'image', url: image2},
            {type: 'image', url: image3},
            {type: 'image', url: image4},
            {type: 'image', url: image5},
            {type: 'image', url: image6},
            {type: 'image', url: image7},
            {type: 'image', url: image8},
            {type: 'image', url: image9},
            {type: 'image', url: image10},
        ]
    }
}

export default function Landing(){

    //Classes
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const classes = useStyles();

    //State
    const [profileSidePanelOpen, setProfileSidePanelOpen] = useState(false);

    const handleSidePanelOpen = () => {
        setProfileSidePanelOpen(!profileSidePanelOpen);
    }

    const TitleDivider = () => {
        return (
            <React.Fragment>
                <div style={{borderBottom: '1px solid lightgray', marginLeft: '10px'}}></div>
                <div style={{border: '1px solid #B6985C', marginLeft: '10px', width: '4em', position: 'relative', top: -6}}></div>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <HeaderNav {...cottageData}></HeaderNav>
            <Header
                data-testid={"main-header"}
                profileSidePanelOpen={profileSidePanelOpen}
                handleSidePanelOpen={() => handleSidePanelOpen()}
            />

            <div className={classes.sectionContainer}>
                {/*SECTION - MAIN IMAGE*/}
                <div className={classes.section1}>
                    {matches ? (
                        <Typography variant={'h2'} color={'white'} fontWeight={"bold"}>
                            BAKOVEN COTTAGES
                        </Typography>) : ( <Typography variant={'h3'} color={'white'} fontWeight={"bold"}>
                        BAKOVEN COTTAGES
                    </Typography>)}
                </div>

                {/*SECTION - ACCOMMODATION*/}
                <div className={classes.content} id={'accommodation'}>
                    {/*<div id={'row0'} style={{display: 'flex', justifyContent: 'center', padding: '1em'}}>*/}
                    {/*    <Typography variant={'h3'}>*/}
                    {/*        ACCOMMODATION*/}
                    {/*    </Typography>*/}
                    {/*</div>*/}
                    <div className={classes.evenColumns}>
                        <div>
                            <Typography variant={'h4'}>
                                Self Catering
                            </Typography>
                            <TitleDivider/>
                            <CottageOverview></CottageOverview>
                            <Typography variant={'h4'}>
                                House Rules
                            </Typography>
                            <TitleDivider/>
                            <CottageRules></CottageRules>
                        </div>
                        <div>
                            <Typography variant={'h4'}>
                                House Rules
                            </Typography>
                            <TitleDivider/>
                            <CottageRules2></CottageRules2>
                            {/*<CottageAttractions {...cottageData}></CottageAttractions>*/}
                        </div>
                        <div>
                            <Typography variant={'h4'}>
                                Location
                            </Typography>
                            <TitleDivider/>
                            <CottageLocation {...cottageData}></CottageLocation>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{alignItems: 'center', margin: '1em', paddingTop: '2em'}} >
                <div id={'gallery'} >
                    <Typography variant={'h4'}>
                        Gallery
                    </Typography>
                    <div>
                        <div style={{borderBottom: '1px solid lightgray', marginTop: '10px'}}></div>
                        <div style={{border: '1px solid #B6985C', width: '4em', position: 'relative', top: -3}}></div>
                    </div>
                </div>
                <div className={classes.gallery}>
                    <ImageSlider {...cottageData.gallery}></ImageSlider>
                </div>
            </div>
            <div className={classes.sectionContainer} id={'bookings'}>
                {/*SECTION - BOOKINGS*/}
                {/*<div id={'row0'} style={{display: 'flex', justifyContent: 'center', padding: '1em'}}>*/}
                {/*    <Typography variant={'h3'}>*/}
                {/*        BOOKINGS*/}
                {/*    </Typography>*/}
                {/*</div>*/}
                <div className={classes.evenColumns}>
                    <div>
                        <Typography variant={'h4'}>
                            How to Book
                        </Typography>
                        <TitleDivider/>
                        <Typography variant={'label'}>
                            To book a cottage at Bakoven, you need to be in good standing order with your Shellhole.
                        </Typography>
                        {/*<Typography variant={'label'}>*/}
                        {/*    Download the 2020-2021 application form <a href={bookingForm} target='_blank'>here</a>, complete and email back to <a href={`mailto:${cottageData.address.email}`} className={classes.link} >{cottageData.address.email}</a>*/}
                        {/*</Typography>*/}
                        <Typography variant={'label'}>
                            Please ensure you have the form signed by your OLD BILL
                        </Typography>
                        <Typography variant={'label'}>
                            We no longer accept facsimiles.
                        </Typography>
                        <Typography variant={'label'}>
                            <div>
                                <ol>
                                    <li>Download the latest booking form <a href={bookingForm} target='_blank'><b>HERE</b></a>.</li>
                                    <li>Complete and sign the form and then email it, together with proof of payment, to <a href={`mailto:${cottageData.address.email}`} className={classes.link} >{cottageData.address.email}</a></li>
                                    <li> Confirmation, instructions, details etc will be emailed back to the client.</li>
                                    <li> Details of how to get to the cottages can be found in the Location section elsewhere on the page</li>
                                </ol>
                            </div>
                        </Typography>
                    </div>
                    <div>
                        {/*El Alamein*/}
                        <Typography variant={'h4'}>
                            Calendar
                        </Typography>
                        <TitleDivider/>
                        <CottageBooking url='https://calendar.google.com/calendar/embed?src=f6v39tplj7hfp3m0kia71jkkd0%40group.calendar.google.com&ctz=Africa%2FJohannesburg&amp;color=%23795548&amp;showTz=0&amp;showTz=0&amp;showCalendars=0&amp;showTitle=1&amp;showTabs=0&amp;showPrint=0'></CottageBooking>
                    </div>
                    <div>
                        {/*Delville Wood*/}
                        <Typography variant={'h4'}>
                            Calendar
                        </Typography>
                        <TitleDivider/>
                        <CottageBooking url="https://calendar.google.com/calendar/embed?src=92m93d72huql1vfsidig3g7pgc%40group.calendar.google.com&ctz=Africa%2FJohannesburg&amp;color=%23795548&amp;showTz=0&amp;showCalendars=0&amp;showTitle=1&amp;showTabs=0&amp;showPrint=0"></CottageBooking>
                    </div>
                </div>
            </div>
            <Footer>
            </Footer>
        </React.Fragment>
    )
}
