import React from 'react';

//Material-UI
import {
    makeStyles,
} from '@material-ui/core';

//Components
import Typography from "../../components/Typography";
import Icons from "../../components/icons/Icons";

const useStyles = makeStyles(() => ({
    headerNav: {
        display: 'flex',
        backgroundColor: 'black',
        height: '2em',
        flexDirection: 'row',
        justifyContent: "flex-end",
        alignItems: "center",
        position: "sticky",
        top: 0
    },
    icon: {
        color: 'white',
    },
    link: {
        color: "inherit",
        textDecoration: "inherit",
        cursor: 'pointer'
    },
}));

export default function HeaderNav(props){

    //Classes
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.headerNav}>
                {/*<img src={logo} className={classes.logo} alt={'MOTH Logo'}/>*/}
                <div>{Icons({
                    name: "call",
                    classes: {icon: classes.icon},
                    size: 0.8
                }).icon}</div>
                <div>&nbsp;</div>
                <a href={`tel:${props.address.tel}`} className={classes.link}>
                    <Typography color={'white'} variant={'nav'}>
                        {props.address.tel}</Typography>
                </a>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>{Icons({
                    name: "mail",
                    classes: {icon: classes.icon},
                    size: 0.8,
                }).icon}</div>
                <div>&nbsp;</div>
                <a href={`mailto:${props.address.email}`} className={classes.link}>
                <Typography color={'white'} variant={'nav'}>{props.address.email}</Typography>
                </a>

                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>{Icons({
                    name: "mail",
                    classes: {icon: classes.icon},
                    size: 0.8,
                }).icon}</div>
                <div>&nbsp;</div>
                <a href={`mailto:${props.address.email2}`} className={classes.link}>
                    <Typography color={'white'} variant={'nav'}>{props.address.email2}</Typography>
                </a>
                <div>&nbsp;</div>
            </div>
        </React.Fragment>
    )
}
