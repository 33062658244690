import React from 'react';
import {makeStyles} from "@material-ui/core";
import Iframe from 'react-iframe'

const useStyles = makeStyles((theme)=>({
    calendar: {
        height:  '350px',
        border: "0",
        width: "345",
        frameBorder: "0",
        scrolling: "no",
        marginBottom: '1em',
        [theme.breakpoints.down('sm')]: {
           width: '98%',
        }
    },
}));

export default function CottageBooking(props){

    const classes = useStyles();

    //uRL format
    //https://calendar.google.com/calendar/embed?src=92m93d72huql1vfsidig3g7pgc%40group.calendar.google.com&ctz=Africa%2FJohannesburg&amp;color=%23795548&amp;showTz=0&amp;showCalendars=0&amp;showTitle=0&amp;showTabs=0&amp;showPrint=0

    return (
        <React.Fragment>
            <div className={classes.calendar}>
                <Iframe url={props.url}
                        width="350px"
                        height="350px"
                        id={"calendar" + props.name}
                        className={classes.calendar}
                        display="initial"
                        position="relative"
                />
            </div>
        </React.Fragment>
    )
}